var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "view" },
    [
      _c("header-section", {
        attrs: {
          heading: "Purchases",
          subheading: "Manage and download your purchased beats and contracts.",
          img: "bg-licenses",
        },
      }),
      _c(
        "section",
        { staticClass: "section section--padding" },
        [
          _vm.isLoading
            ? [_c("VLoadSpinner")]
            : [
                _c(
                  "div",
                  { staticClass: "container container--lg" },
                  [
                    _c("BeatTable", {
                      attrs: {
                        parentState: _vm.ParentStateType.Purchases,
                        beatPlaylist: _vm.boughtBeats,
                      },
                    }),
                  ],
                  1
                ),
              ],
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }